import { Dropdown, Collapse, Modal, Offcanvas, Carousel } from 'bootstrap';

//Components
import '../scss/theme.scss';
import './components/off-canvas.js';
import Cookies from 'js-cookie'
import './components/sticky-header.js';

//Images
import '../image/BK-house-logo.png';
import '../image/hero-image.png';
import '../image/default-card.jpg';
import '../image/default-header.jpg';
import '../image/bg-footer.jpg';
import '../image/slide.png';
import '../image/bg-upcoming-programs.jpg';
import '../image/instagram-feed.png';
import '../image/bk-house-map.jpg';
import '../image/logo-2.png';
import '../image/logo-1.png';
import '../image/ornament-one.png';
import '../image/ornament-two.png';
import '../image/ornament-three.png';




//Check for cookie
jQuery(window).on('load',function(){
	//Load Announcementbar
	if(Cookies.get('co_topbar')) {
	} else {
		jQuery('#topBarCollapse').collapse('show');
	}
});

jQuery(document).ready(function (jQuery) {
	//ANNOUNCEMENT BAR
	jQuery('#topBarCollapse').on('hidden.bs.collapse', function(e) {
		Cookies.set('co_topbar', 'set', { expires: 1 });
	});

});



window.addEventListener('DOMContentLoaded', function() {
    //SVG INJECTOR
    const elementsToInject = document.querySelectorAll('.inject-me');
    const injectorOptions = {
        evalScripts: 'once'
    };
    let injector = new SVGInjector(injectorOptions);
    if (elementsToInject.length) {
        injector.inject(elementsToInject);
    }
}, false);
import { Offcanvas } from 'bootstrap';
window.onload = function() {
	//Animate Hamburger on menu open/close
	const ocNav = document.querySelector('#co-offcanvas');
	const ocBtn = document.querySelector('#navbarOffCanvas');

	ocNav.addEventListener('show.bs.offcanvas',() => {
		ocBtn.classList.add('menu-open');
		ocBtn.classList.remove('menu-closed');
	});
	ocNav.addEventListener('hide.bs.offcanvas',() => {
		ocBtn.classList.remove('menu-open');
		ocBtn.classList.add('menu-closed');
	});
};